import React from "react";
import { useResolvedPath, useMatch, Link } from "react-router-dom";
import { useMoralis } from "react-moralis";
import Account from "../UI/Account/Account";
import Chains from "../UI/Chains/Chains";
import NativeBalance from "../UI/NativeBalance";

const Header = () => {
  /* const { isAuthenticated, account } = useMoralis(); */

  const HeaderLink = ({ to, children }) => {
    let path = useResolvedPath(to);
    let match = useMatch({ path: path.pathname, end: true });

    return (
      <Link
        to={to}
        className={match ? "headerlink headerlink-active" : "headerlink"}
      >
        {children}
      </Link>
    );
  };

  /* return (
    <>
      <header id="nav" className="header">
        <ul className="headergrid w-list-unstyled">
          <li
            id="w-node-_69dd9182-5956-e8ac-6c98-598fc0324d2a-3e4d4b6a"
            className="headerlistitem"
          >
            <NavLink to="/" className="headerlogolink">
              <img
                src="images/Mogle-Logo-Black.png"
                sizes="(max-width: 479px) 153.8046875px, (max-width: 767px) 20vw, 156.4140625px"
                srcSet="images/Mogle-Logo-Black-p-500.png 500w, images/Mogle-Logo-Black-p-800.png 800w, images/Mogle-Logo-Black-p-1080.png 1080w, images/Mogle-Logo-Black-p-1600.png 1600w, images/Mogle-Logo-Black-p-2000.png 2000w, images/Mogle-Logo-Black-p-2600.png 2600w, images/Mogle-Logo-Black.png 3582w"
                alt=""
                className="headerlogo"
              />
            </NavLink>
          </li>
          <Menu
            defaultSelectedKeys={["dashboard"]}
            mode="horizontal"
            theme="light"
            style={{
              display: "flex",
              width: "100%",
              fontSize: "12px",
              fontWeight: "500",
            }}
            className="headergrid w-list-unstyled"
          >
            <Menu.Item key="discord" className="headerlistitem">
              <a
                href="https://discord.gg/FsQhcdXkRz"
                className="headerlink"
                target="_blank"
                rel="noreferrer"
              >
                Join Discord
              </a>
            </Menu.Item>
            {!isAuthenticated || !account ? null : (
              <>
                <Menu.Item key="marketplace" className="headerlistitem">
                  <NavLink to="/marketplace" className="headerlink">
                    Marketplace
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="analytics" className="headerlistitem">
                  <NavLink to="/analytics" className="headerlink">
                    Analytics
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="dashboard" className="headerlistitem">
                  <NavLink to="/" className="headerlink headerlink-clicked">
                    Dashboard
                  </NavLink>
                </Menu.Item>
              </>
            )}
          </Menu>
          <li className="headerlistitem">
            <Chains />
          </li>
          <li className="headerlistitem">
            <NativeBalance />
          </li>
          <li className="headerlistitem">
            <Account />
          </li>
        </ul>
      </header>
    </>
  ); */

  return (
    <>
      <header id="nav" className="header">
        <ul className="headergrid w-list-unstyled">
          <li
            id="w-node-_69dd9182-5956-e8ac-6c98-598fc0324d2a-3e4d4b6a"
            className="headerlistitem"
          >
            <Link to="/" className="headerlogolink">
              <img
                src="images/Mogle-Logo-Black.png"
                sizes="(max-width: 479px) 153.8046875px, (max-width: 767px) 20vw, 156.4140625px"
                srcSet="images/Mogle-Logo-Black-p-500.png 500w, images/Mogle-Logo-Black-p-800.png 800w, images/Mogle-Logo-Black-p-1080.png 1080w, images/Mogle-Logo-Black-p-1600.png 1600w, images/Mogle-Logo-Black-p-2000.png 2000w, images/Mogle-Logo-Black-p-2600.png 2600w, images/Mogle-Logo-Black.png 3582w"
                alt=""
                className="headerlogo"
              />
            </Link>
          </li>
          <li className="headerlistitem">
            <a
              href="https://discord.gg/FsQhcdXkRz"
              className="headerlink"
              target="_blank"
              rel="noreferrer"
            >
              Join Discord
            </a>
          </li>
          <li className="headerlistitem">
            <HeaderLink to="/simulator">Simulator</HeaderLink>
          </li>
          {/* {!isAuthenticated || !account ? null : (
            <>
              <li className="headerlistitem">
                <HeaderLink to="/marketplace">Marketplace</HeaderLink>
              </li>
              <li className="headerlistitem">
                <HeaderLink to="/analytics">Analytics</HeaderLink>
              </li>
              <li className="headerlistitem">
                <HeaderLink to="/">Dashboard</HeaderLink>
              </li>
            </>
          )}
          <li className="headerlistitem">
            <Chains />
          </li>
          <li className="headerlistitem">
            <NativeBalance />
          </li>
          <li className="headerlistitem">
            <Account />
          </li> */}
        </ul>
      </header>
    </>
  );
};

export default Header;
