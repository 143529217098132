import React from "react";
import { useMoralis } from "react-moralis";
import Landing from "./Landing/Landing";
import Dashboard from "./Features/Dashboard/Dashboard";

const Index = () => {
  /* const { isAuthenticated, account } = useMoralis();

  if (!isAuthenticated || !account) {
    return <Landing />;
  } else {
    return <Dashboard />;
  } */
  return <Landing />;
};

export default Index;
