import React, { useEffect } from "react";
import amplitude from "amplitude-js";

const PAGE = "Landing";

const Landing = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("View " + PAGE + " Page", {
      Page: PAGE,
    });
  }, []);

  return (
    <>
      <div className="introsection wf-section">
        <div className="introcolumns w-row">
          <div className="introcolumnone w-col w-col-6">
            <h1 className="introheading">Product Suite for Freelancers in Web3</h1>
            <p className="introparagraph">
              Manage your freelance business within the web3 ecosystem.
            </p>
          </div>
          <div className="introcolumntwo w-col w-col-3">
            <img
              src="images/IntroImage.png"
              loading="lazy"
              alt=""
              className="introimage"
            />
          </div>
        </div>
      </div>
      <div className="brandssection wf-section"></div>
      <div className="bannersection wf-section">
        <div className="bannerdiv">
          <h3 className="bannerheading">1099 for Web3</h3>
          <p className="bannerparagraph">
            As work becomes more decentralized, freelancers need new
            tools to manage their work on the blockchain. Mogle builds
            freelance products that optimize for earning in web3.
            <br />
          </p>
        </div>
      </div>
      <div className="stepssection wf-section">
        <div className="stepscolumns w-row">
          <div className="stepscolumnone w-col w-col-3">
            <h3 className="stepsheading">Features</h3>
          </div>
          <div className="stepscolumntwo w-col w-col-3">
            <div className="stepdiv">
              <h4 className="steponeheading">1. Dashboard</h4>
              <p className="steponeparagraph">
                Manage your different sources of income across organizations and blockchains.
              </p>
              <div className="steponeimagediv">
                <img
                  src="images/StepOneImage.png"
                  loading="lazy"
                  width="172"
                  sizes="(max-width: 991px) 91px, 172px"
                  srcSet="images/StepOneImage-p-500.png 500w, images/StepOneImage-p-800.png 800w, images/StepOneImage-p-1080.png 1080w, images/StepOneImage.png 1400w"
                  alt=""
                  className="steponeimage"
                />
              </div>
            </div>
          </div>
          <div className="stepscolumnthree w-col w-col-3">
            <div className="stepdiv steptwocolor">
              <h4 className="steptwoheading">2. Analytics</h4>
              <p className="steptwoparagraph">
                Aggregate and analyze your income data to find key insights.
              </p>
              <img
                src="images/StepTwoImage.png"
                loading="lazy"
                alt=""
                className="steptwoimage"
              />
            </div>
          </div>
          <div className="stepscolumnfour w-col w-col-3">
            <div className="stepdiv stepthreecolor">
              <h4 className="stepthreeheading">3. Marketplace</h4>
              <p className="stepthreeparagraph">
                Mint, sell, and buy freelance contract NFTs.
              </p>
              <img
                src="images/StepThreeImage.png"
                loading="lazy"
                width="279"
                alt=""
                className="stepthreeimage"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="problemsection wf-section">
        <div className="problemcolumns w-row">
          <div className="problemcolumnone w-col w-col-9">
            <h3 className="problemheading">Why Mogle?</h3>
            <p className="problemparagraph">
              Freelancers face unique challenges.
              <br />
            </p>
            <div className="problemcardscolumns w-row">
              <div className="problemcardscolumnone w-col w-col-4">
                <div className="problemcarddiv">
                  <p className="problemcardoneparagraph">
                    According to a 2017 study, the top concern of freelancers
                    nationwide is income predictability.
                  </p>
                  <p className="problemonesource">Source: Freelancers Union</p>
                </div>
              </div>
              <div className="problemcardscolumntwo w-col w-col-4">
                <div className="problemcarddiv problemtwocolor">
                  <p className="problemtwoparagraph">
                    63% of freelancers
                    <br />
                    dip into their savings at least once a month compared to 20%
                    of full-time <br />
                    employees.
                  </p>
                  <p className="problemtwosource">Source: Freelancers Union</p>
                </div>
              </div>
              <div className="problemcardscolumnthree w-col w-col-4">
                <div className="problemcarddiv problemthreecolor">
                  <p className="problemthreeparagraph">
                    Gig workers are vastly more likely to have a high Anxiety
                    Index score than those in traditional employment.
                  </p>
                  <p className="problemthreesource">Source: Edison Research</p>
                </div>
              </div>
            </div>
          </div>
          <div className="problemcolumntwo w-col w-col-3">
            <img
              src="images/ProblemImage.png"
              loading="lazy"
              alt=""
              className="problemimage"
            />
          </div>
        </div>
      </div>
      <div className="actionsection wf-section">
        <div className="actiondiv">
          <h3 className="actionheading">W-2 Stability for 1099 Freelancers</h3>
          <p className="actionparagraph">
            Combine the freedom of freelancing with the stability of full-time
            employment.
          </p>
        </div>
      </div>
    </>
  );
};

export default Landing;
