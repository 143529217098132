import React, { useState } from "react";
import MogleMarketplace from "../utils/MogleMarketplace.json";

const MarketplaceContext = React.createContext();
const REACT_APP_MOGLE_MARKETPLACE_ETHEREUM_CONTRACT_ADDRESS =
  process.env.REACT_APP_MOGLE_MARKETPLACE_ETHEREUM_CONTRACT_ADDRESS;
const REACT_APP_MOGLE_MARKETPLACE_GOERLI_CONTRACT_ADDRESS =
  process.env.REACT_APP_MOGLE_MARKETPLACE_GOERLI_CONTRACT_ADDRESS;
const REACT_APP_MOGLE_MARKETPLACE_MUMBAI_CONTRACT_ADDRESS =
  process.env.REACT_APP_MOGLE_MARKETPLACE_MUMBAI_CONTRACT_ADDRESS;
const MOGLE_MARKETPLACE = MogleMarketplace.abi;

const useMarketplace = () => {
  const context = React.useContext(MarketplaceContext);
  if (context === undefined) {
    throw new Error("useMarketplace() must be used within MarketplaceProvider");
  }
  return context;
};

function MarketplaceProvider({ children }) {
  const [contractAddress, setContractAddress] = useState({
    "0x1": REACT_APP_MOGLE_MARKETPLACE_ETHEREUM_CONTRACT_ADDRESS,
    "0x5": REACT_APP_MOGLE_MARKETPLACE_GOERLI_CONTRACT_ADDRESS,
    "0x13881": REACT_APP_MOGLE_MARKETPLACE_MUMBAI_CONTRACT_ADDRESS,
  });
  const [contractAbi, setContractAbi] = useState(MOGLE_MARKETPLACE);

  return (
    <MarketplaceContext.Provider
      value={{
        contractAddress,
        contractAbi,
      }}
    >
      {children}
    </MarketplaceContext.Provider>
  );
}

export { MarketplaceProvider, useMarketplace };
