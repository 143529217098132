import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import amplitude from "amplitude-js";
import { Slider } from "@mui/material";
import truncateMiddle from "truncate-middle";
import Loader from "../UI/Loader";

const ADDRESSES = {
  "0x639749b7b08aee65039c21d8a411103c6cebebf0": truncateMiddle(
    "0x639749b7b08aee65039c21d8a411103c6cebebf0" || "",
    3,
    3,
    "..."
  ),
  "0xb1b7586656116d546033e3baff69bfcd6592225e": truncateMiddle(
    "0xb1b7586656116d546033e3baff69bfcd6592225e" || "",
    3,
    3,
    "..."
  ),
  "0x2e7f4dd3acd226ddae10246a45337f815cf6b3ff": truncateMiddle(
    "0x2e7f4dd3acd226ddae10246a45337f815cf6b3ff" || "",
    3,
    3,
    "..."
  ),
  "0x61ffe691821291d02e9ba5d33098adcee71a3a17": truncateMiddle(
    "0x61ffe691821291d02e9ba5d33098adcee71a3a17" || "",
    3,
    3,
    "..."
  ),
  "0xdc4471ee9dfca619ac5465fde7cf2634253a9dc6": truncateMiddle(
    "0xdc4471ee9dfca619ac5465fde7cf2634253a9dc6" || "",
    3,
    3,
    "..."
  ),
  "0x40a57a5d9b618b9a49307b530736b4f51e008741": truncateMiddle(
    "0x40a57a5d9b618b9a49307b530736b4f51e008741" || "",
    3,
    3,
    "..."
  ),
  "0x03fadce719ca1cfe4f114e18c862be25e40fbf3c": truncateMiddle(
    "0x03fadce719ca1cfe4f114e18c862be25e40fbf3c" || "",
    3,
    3,
    "..."
  ),
  "0x325fb0d23b9526e90477c74e18f47670cc996606": truncateMiddle(
    "0x325fb0d23b9526e90477c74e18f47670cc996606" || "",
    3,
    3,
    "..."
  ),
  "0x95793f83098ec6d2deeb6e1ea83d199c8cd92296": truncateMiddle(
    "0x95793f83098ec6d2deeb6e1ea83d199c8cd92296" || "",
    3,
    3,
    "..."
  ),
  "0xc072cbb7d7429415ce2dcbe88aa25ad33624c800": truncateMiddle(
    "0xc072cbb7d7429415ce2dcbe88aa25ad33624c800" || "",
    3,
    3,
    "..."
  ),
};
const DEFAULT_IS_PAGE_LOADING = true;
const DEFAULT_ADDRESS = "Custom";
const DEFAULT_CUSTOM = true;
const DEFAULT_MEAN = 15000;
const DEFAULT_STD = 5000;
const DEFAULT_MONTHS = 50;
const DEFAULT_CUSTOM_PARAMS = {
  mean: DEFAULT_MEAN,
  std: DEFAULT_STD,
  months: DEFAULT_MONTHS,
};
const DEFAULT_IS_LOCKED = false;
const DEFAULT_PLANS = {
  self: true,
  group: false,
};
const DEFAULT_PLAN = "self";
const DEFAULT_PLAN_DESCRIPTION = {
  self: "Self-insurance is akin to allocating a portion of monthly income to an individual savings account to reach into when monthly income falls below coverage.",
  group:
    "Group insurance involves distributing the risk of falling short on income with similar freelancers by pooling income together and redistributing it. This plan significantly decreases premium prices but at the cost of limiting potential upside as well.",
};
const DEFAULT_MEMBERS_SLIDER = {
  self: 1,
  group: 50,
};
const DEFAULT_COVERAGE_SLIDER = 0;
const DEFAULT_STATS = {};
const DEFAULT_DATA = {};
const PAGE = "Simulator";

const Simulator = () => {
  const [isPageLoading, setIsPageLoading] = useState(DEFAULT_IS_PAGE_LOADING);
  const [address, setAddress] = useState(DEFAULT_ADDRESS);
  const [custom, setCustom] = useState(DEFAULT_CUSTOM);
  const [mean, setMean] = useState(DEFAULT_MEAN);
  const [std, setStd] = useState(DEFAULT_STD);
  const [months, setMonths] = useState(DEFAULT_MONTHS);
  const [customParams, setCustomParams] = useState(DEFAULT_CUSTOM_PARAMS);
  const [isLocked, setIsLocked] = useState(DEFAULT_IS_LOCKED);
  const [plans, setPlans] = useState(DEFAULT_PLANS);
  const [plan, setPlan] = useState(DEFAULT_PLAN);
  const [planDescription, setPlanDescription] = useState(
    DEFAULT_PLAN_DESCRIPTION[DEFAULT_PLAN]
  );
  const [membersSlider, setMembersSlider] = useState(
    DEFAULT_MEMBERS_SLIDER[DEFAULT_PLAN]
  );
  const [coverageSlider, setCoverageSlider] = useState(DEFAULT_COVERAGE_SLIDER);
  const [stats, setStats] = useState(DEFAULT_STATS);
  const [data, setData] = useState(DEFAULT_DATA);
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  const meanRef = useRef();
  const stdRef = useRef();
  const monthsRef = useRef();

  useLayoutEffect(() => {
    const getData = async () => {
      setIsPageLoading(true);
      let income_data, labels;
      if (!isLocked) {
        const get_income_data = await axios.post(
          "/api/e/v1/simulator/get_income_data",
          {
            address,
            mean: customParams["mean"],
            std: customParams["std"],
            months: customParams["months"],
          }
        );
        income_data = get_income_data.data.income_data;
        labels = get_income_data.data.labels;
      } else {
        income_data = data.datasets[0].data;
        labels = data.labels;
      }
      const plan = Object.keys(plans).filter((plan) => plans[plan])[0];
      const get_mogle_data = await axios.post(
        "/api/e/v1/simulator/get_mogle_data",
        {
          address,
          plan,
          membersSlider,
          coverageSlider,
          income_data,
        }
      );
      console.log("get_mogle_data:", get_mogle_data);
      const { stats, datasets } = get_mogle_data.data;
      setStats(stats);
      setData({ datasets, labels });
      setIsPageLoading(false);
    };
    getData();
    return () => {};
  }, [
    address,
    customParams,
    isLocked,
    plans,
    membersSlider,
    coverageSlider,
    setStats,
    setData,
    setIsPageLoading,
  ]);

  useEffect(() => {
    amplitude.getInstance().logEvent("View " + PAGE + " Page", {
      Page: PAGE,
    });
  }, []);

  const handleAddressChange = (e) => {
    if (e.target.value === "Custom") {
      setCustom(true);
    } else {
      setCustom(false);
    }
    setAddress(e.target.value);
  };

  const handleMeanChange = (e) => {
    setMean(e.target.value);
  };

  const handleStdChange = (e) => {
    setStd(e.target.value);
  };

  const handleMonthsChange = (e) => {
    setMonths(e.target.value);
  };

  const handleSimClick = () => {
    setCustomParams({
      mean: meanRef.current.value,
      std: stdRef.current.value,
      months: monthsRef.current.value,
    });
  };

  const handleLockedClick = () => {
    if (isLocked) {
      setIsLocked(false);
    } else {
      setIsLocked(true);
    }
  };

  const handlePlansClick = (plan) => {
    if (plan === "self") {
      setPlans({
        self: true,
        group: false,
      });
    } else if (plan === "group") {
      setPlans({
        self: false,
        group: true,
      });
    }
    setPlan(plan);
    setPlanDescription(DEFAULT_PLAN_DESCRIPTION[plan]);
    setMembersSlider(DEFAULT_MEMBERS_SLIDER[plan]);
  };

  const handleMembersChange = (e) => {
    setMembersSlider(e.target.value);
  };

  const handleCoverageChange = (e) => {
    setCoverageSlider(e.target.value);
  };

  return (
    <>
      <div className="page">
        <div className="container">
          <div className="card">
            <h1>Simulator</h1>
            <p>
              Mogle provides{" "}
              <b>
                <a
                  href="https://medium.com/@joshchoi4881/a-freelance-future-20a684359cd8"
                  className="primary-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  W-2 stability for 1099 freelancers
                </a>
              </b>{" "}
              through decentralized insurance on income stability.
            </p>
            <p>
              Mogle is not a centralized insurer that collects premiums and
              determines payouts. Our insurance is decentralized - you have
              governance and ownership over your own collective income pools,
              whether you choose to insure by yourself or with a group.
            </p>
            <h2>Address</h2>
            <select
              value={address}
              onChange={handleAddressChange}
              disabled={isLocked}
            >
              <option value="Custom">Custom</option>
              <option value="0x639749b7b08aee65039c21d8a411103c6cebebf0">
                {ADDRESSES["0x639749b7b08aee65039c21d8a411103c6cebebf0"]}
              </option>
              <option value="0xb1b7586656116d546033e3baff69bfcd6592225e">
                {ADDRESSES["0xb1b7586656116d546033e3baff69bfcd6592225e"]}
              </option>
              <option value="0x2e7f4dd3acd226ddae10246a45337f815cf6b3ff">
                {ADDRESSES["0x2e7f4dd3acd226ddae10246a45337f815cf6b3ff"]}
              </option>
              <option value="0x61ffe691821291d02e9ba5d33098adcee71a3a17">
                {ADDRESSES["0x61ffe691821291d02e9ba5d33098adcee71a3a17"]}
              </option>
              <option value="0xdc4471ee9dfca619ac5465fde7cf2634253a9dc6">
                {ADDRESSES["0xdc4471ee9dfca619ac5465fde7cf2634253a9dc6"]}
              </option>
              <option value="0x40a57a5d9b618b9a49307b530736b4f51e008741">
                {ADDRESSES["0x40a57a5d9b618b9a49307b530736b4f51e008741"]}
              </option>
              <option value="0x03fadce719ca1cfe4f114e18c862be25e40fbf3c">
                {ADDRESSES["0x03fadce719ca1cfe4f114e18c862be25e40fbf3c"]}
              </option>
              <option value="0x325fb0d23b9526e90477c74e18f47670cc996606">
                {ADDRESSES["0x325fb0d23b9526e90477c74e18f47670cc996606"]}
              </option>
              <option value="0x95793f83098ec6d2deeb6e1ea83d199c8cd92296">
                {ADDRESSES["0x95793f83098ec6d2deeb6e1ea83d199c8cd92296"]}
              </option>
              <option value="0xc072cbb7d7429415ce2dcbe88aa25ad33624c800">
                {ADDRESSES["0xc072cbb7d7429415ce2dcbe88aa25ad33624c800"]}
              </option>
            </select>
            {custom && (
              <>
                <label>Average Monthly Income</label>
                <input
                  ref={meanRef}
                  type="number"
                  value={mean}
                  onChange={handleMeanChange}
                  disabled={isLocked}
                />
                <label>Standard Deviation of Monthly Income</label>
                <input
                  ref={stdRef}
                  type="number"
                  value={std}
                  onChange={handleStdChange}
                  disabled={isLocked}
                />
                <label>Months</label>
                <input
                  ref={monthsRef}
                  type="number"
                  value={months}
                  onChange={handleMonthsChange}
                  disabled={isLocked}
                />
                <div className="buttons">
                  <button
                    id="submit"
                    className="button btn"
                    onClick={() => handleSimClick()}
                    disabled={isLocked}
                  >
                    Simulate
                  </button>
                  <button
                    id={isLocked ? "locked-clicked" : "locked-unclicked"}
                    className="button btn"
                    onClick={() => handleLockedClick()}
                  >
                    Lock
                  </button>
                </div>
              </>
            )}
            <h2>Plans</h2>
            <div className="buttons">
              <button
                id={plans["self"] ? "self-clicked" : "self-unclicked"}
                className="button btn"
                onClick={() => handlePlansClick("self")}
              >
                Self
              </button>
              <button
                id={plans["group"] ? "group-clicked" : "group-unclicked"}
                className="button btn"
                onClick={() => handlePlansClick("group")}
              >
                Group
              </button>
            </div>
            <div className="fixed-height">
              {isPageLoading ? (
                <>
                  <Loader small />
                </>
              ) : (
                <>
                  <p>{planDescription}</p>
                </>
              )}
            </div>
            <div className="fixed-height">
              <h3>Members</h3>
              {isPageLoading ? (
                <>
                  <Loader small />
                </>
              ) : (
                <>
                  <p className="highlight">{membersSlider}</p>
                </>
              )}
              <p>
                The number of freelancers in your insurance pool. As the
                membership increases, the distributed premium cost decreases.
              </p>
              {isPageLoading ? (
                <>
                  <Loader small />
                </>
              ) : plan === "group" ? (
                <>
                  <Slider
                    aria-label="Members"
                    min={30}
                    max={500}
                    step={null}
                    marks={[
                      {
                        value: 30,
                        label: "30",
                      },
                      {
                        value: 50,
                        label: "50",
                      },
                      {
                        value: 100,
                        label: "100",
                      },
                      {
                        value: 200,
                        label: "200",
                      },
                      {
                        value: 300,
                        label: "300",
                      },
                      {
                        value: 400,
                        label: "400",
                      },
                      {
                        value: 500,
                        label: "500",
                      },
                    ]}
                    value={membersSlider}
                    onChange={handleMembersChange}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="row-space-between">
              <div className="fixed-height">
                <h3>{plan === "self" ? "Self" : "Distributed"} Coverage</h3>
                {isPageLoading ? (
                  <>
                    <Loader small />
                  </>
                ) : (
                  <>
                    <p className="highlight">
                      {Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        plan === "self"
                          ? stats["self"]["coverage"]
                          : stats["distributed"]["coverage"]
                      )}{" "}
                      USDC
                    </p>
                  </>
                )}
              </div>
              {plan === "group" ? (
                <>
                  <div className="fixed-height">
                    <h3>Group Coverage</h3>
                    <div className="right">
                      {isPageLoading ? (
                        <>
                          <Loader small />
                        </>
                      ) : (
                        <>
                          <p className="highlight">
                            {Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(stats["group"]["coverage"])}{" "}
                            USDC
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <p>
              The target monthly income you're optimizing for. As the coverage
              increases, the premium cost increases.
            </p>
            <Slider
              aria-label="Coverage"
              min={-3}
              max={3}
              step={null}
              marks={[
                {
                  value: -3,
                  label: "-3SD",
                },
                {
                  value: -2,
                  label: "-2SD",
                },
                {
                  value: -1,
                  label: "-1SD",
                },
                {
                  value: 0,
                  label: "Mean",
                },
                {
                  value: 1,
                  label: "+1SD",
                },
                {
                  value: 2,
                  label: "+2SD",
                },
                {
                  value: 3,
                  label: "+3SD",
                },
              ]}
              value={coverageSlider}
              onChange={handleCoverageChange}
            />
            <div className="row-space-between">
              <div className="fixed-height">
                <h3>
                  {plan === "self" ? "Self" : "Distributed"} Monthly Premium
                </h3>
                {isPageLoading ? (
                  <>
                    <Loader small />
                  </>
                ) : (
                  <>
                    <p className="highlight">
                      {Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        plan === "self"
                          ? stats["self"]["EV"]
                          : stats["distributed"]["EV"]
                      )}{" "}
                      USDC
                    </p>
                  </>
                )}
              </div>
              {plan === "group" ? (
                <>
                  <div className="fixed-height">
                    <h3>Group Monthly Premium</h3>
                    <div className="right">
                      {isPageLoading ? (
                        <>
                          <Loader small />
                        </>
                      ) : (
                        <>
                          <p className="highlight">
                            {Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(stats["group"]["EV"])}{" "}
                            USDC
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <p>
              The amount you need to set aside monthly into your pool to
              optimize for your target monthly income.
            </p>
            <div className="row-space-between">
              <div className="fixed-height">
                <h3>{plan === "self" ? "Self" : "Distributed"} Pool Balance</h3>
                {isPageLoading ? (
                  <>
                    <Loader small />
                  </>
                ) : (
                  <>
                    <p className="highlight">
                      {Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(stats["net"])}{" "}
                    </p>
                  </>
                )}
              </div>
              {plan === "group" ? (
                <>
                  <div className="fixed-height">
                    <h3>Group Pool $</h3>
                    <div className="right">
                      {isPageLoading ? (
                        <>
                          <Loader small />
                        </>
                      ) : (
                        <>
                          <p className="highlight">
                            {Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(stats["group"]["EV"])}{" "}
                            USDC
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {isPageLoading ? (
              <>
                <Loader small />
              </>
            ) : (
              <>
                <i>
                  {stats["net"] > 0
                    ? "This is your $ to take out, invest, or lend to other freelancers."
                    : "You can choose to lend from other freelancers to balance your pool."}
                </i>
              </>
            )}
            <br />
            <p>
              The resulting Mogle pool balance after simulated months. A
              positive balance indicates higher income performance (i.e.
              requiring less payouts than premiums paid). A negative balance
              indicates lower income performance (i.e. requiring more payouts
              than premiums paid). This number averages out to $0 over time.
            </p>
            <br />
            <Line options={options} data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Simulator;
