import React, { useEffect } from "react";
import axios from "axios";
import amplitude from "amplitude-js";

const PAGE = "Contact";

const Contact = () => {
  useEffect(() => {
    amplitude.getInstance().logEvent("View " + PAGE + " Page", {
      Page: PAGE,
    });
  }, []);

  const handleExpressClick = async () => {
    const res = await axios.get("/api/e");
    console.log(res);
  };

  const handleFlaskClick = async () => {
    const res = await axios.get("/api/f");
    console.log(res);
  };

  return (
    <>
      <div className="page">
        <div className="container">
          <div className="card">
            <h1>Contact</h1>
            <p>
              Please feel free to reach out to us at mogleinsurance@gmail.com or
              (646) 439-6433 with any feedback or questions.
            </p>
            <button onClick={handleExpressClick}>Express</button>
            <button onClick={handleFlaskClick}>Flask</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
