import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer wf-section">
        <div className="footerdiv">
          <div className="footercontainer">
            <Link to="/" className="footerlogolink">
              <img
                src="images/Mogle-Logo-White.png"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 89vw, (max-width: 991px) 87vw, 86vw"
                srcSet="images/Mogle-Logo-White-p-500.png 500w, images/Mogle-Logo-White-p-800.png 800w, images/Mogle-Logo-White-p-1080.png 1080w, images/Mogle-Logo-White-p-1600.png 1600w, images/Mogle-Logo-White-p-2000.png 2000w, images/Mogle-Logo-White-p-2600.png 2600w, images/Mogle-Logo-White.png 3582w"
                alt=""
                className="footerlogo"
              />
            </Link>
            <div className="footerlinkdiv">
              <Link to="/contact" className="footerlink">
                Contact
              </Link>
            </div>
          </div>
          <div className="footerparagraph">
            Copyright © 2021 Mogle, Inc. All rights reserved.
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
