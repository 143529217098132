import React from "react";

const Loader = ({ header, small }) => {
  const size = small ? "20px" : "65px";
  const load = (
    <>
      {header && (
        <div className="row justify-content-center">
          <h1>{header}...</h1>
        </div>
      )}
      <svg
        className="spinner"
        width={size}
        height={size}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </>
  );
  return small ? (
    <>{load}</>
  ) : (
    <>
      <div className="page">
        <div className="container">{load}</div>
      </div>
    </>
  );
};

export default Loader;
