import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useMoralis } from "react-moralis";
import "antd/dist/antd.css";
import Header from "./components/Layout/Header";
import Index from "./components/Index";
import Contact from "./components/Landing/Contact";
import Simulator from "./components/Landing/Simulator";
import Analytics from "./components/Features/Analytics/Analytics";
import Marketplace from "./components/Features/Marketplace/Marketplace";
import Footer from "./components/Layout/Footer";
import "./styles/App.css";

const App = () => {
  /* const { isAuthenticated, isWeb3Enabled, isWeb3EnableLoading, enableWeb3 } =
    useMoralis();

  useEffect(() => {
    const connectorId = window.localStorage.getItem("connectorId");
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
      enableWeb3({ provider: connectorId });
  }, [isAuthenticated, isWeb3Enabled, isWeb3EnableLoading, enableWeb3]); */

  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/simulator" element={<Simulator />} />
          {/* <Route path="/analytics" element={<Analytics />} />
          <Route path="/marketplace" element={<Marketplace />} /> */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
