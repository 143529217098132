import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { MoralisProvider } from "react-moralis";
/* import { NotificationProvider } from "web3uikit"; */
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import amplitude from "amplitude-js";
import { MarketplaceProvider } from "./providers/MarketplaceProvider";
import App from "./App";
import reducers from "./reducers";
import "./styles/index.css";
import "./styles/mogle.css";

const REACT_APP_AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;
const REACT_APP_MORALIS_APP_ID = process.env.REACT_APP_MORALIS_DEV_APP_ID;
const REACT_APP_MORALIS_SERVER_URL =
  process.env.REACT_APP_MORALIS_DEV_SERVER_URL;

/* const store = createStore(reducers, {}, applyMiddleware(reduxThunk)); */

amplitude.getInstance().init(REACT_APP_AMPLITUDE_API_KEY);

ReactDOM.render(
  <React.StrictMode>
    {/* <Provider store={store}>
    <MoralisProvider
      appId={REACT_APP_MORALIS_APP_ID}
      serverUrl={REACT_APP_MORALIS_SERVER_URL}
    >
      <NotificationProvider>
      <MarketplaceProvider> */}
    <App />
    {/* </MarketplaceProvider>
      </NotificationProvider>
    </MoralisProvider>
    </Provider> */}
  </React.StrictMode>,
  document.getElementById("root")
);
